.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f0f1e9;
  border-bottom: 2px solid #000;
}

.header h1 {
  font-size: 15px;
  margin: 0;
}

.header-menu {
  display: flex;
  gap: 05px;
}

.header-menu a {
  text-decoration: none;
  color: #333;
  font-size: 15px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.header-menu a:hover {
  color: #007bff;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}