.home-page {
  max-width: 960px;
  margin: 0 auto;
  padding: 34px;
  font-family: Arial, sans-serif;
  background-color: #f0f1e9;
}

.header {
  text-align: center;
  margin-bottom: 32px;
}

.header h1 {
  font-size: 36px;
}

.hero {
  text-align: center;
  margin-bottom: 48px;
}

.hero-title {
  font-size: 55px;
  margin-bottom: 16px;
}

.hero-description {
  font-size: 20px;
  margin-bottom: 16px;
}

.conversion-card {
  background-color: #e3fe55;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.input-container input,
.input-container select {
  padding: 8px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.to-text {
  margin: 0 36px;
  font-weight: bold;
}

.convert-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.result-text {
  font-size: 18px;
  margin-top: 16px;
}

.other-conversions {
  text-align: center;
}

.other-conversions-title {
  font-size: 45px;
  margin-bottom: 24px;
}

.conversions-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.conversion-link {
  display: block;
  padding: 16px;
  font-size: 16px;
  background-color: #fff;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
/* ... previous CSS code ... */

.conversion-calculator {
  margin-top: 60px;
  text-align: center;
}

.conversion-calculator-title {
  font-size: 36px;
  margin-bottom: 30px;
}

.conversion-calculator-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  max-width: 960px;
  margin: 0 auto;
}

.conversion-calculator-item {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.conversion-calculator-item h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.conversion-calculator-item p {
  font-size: 16px;
  color: #666;
}