/* Remove the leading dot from the .conversion-page selector */
.conversion-page {
    max-width: 960px;
    margin: 0 auto;
    padding: 24px;
    font-family: Arial, sans-serif;
    background-color: #f0f1e9;
  }
  
  .header {
    text-align: center;
    margin-bottom: 32px;
  }
  
  .header h1 {
    font-size: 36px;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 48px;
  }
  
  .hero-title {
    font-size: 55px;
    margin-bottom: 16px;
  }
  
  .hero-description {
    font-size: 26px;
    margin-bottom: 16px;
  }
  
  .conversion-card {
    background-color: #e3fe55;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;
  }
  
  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .input-container input,
  .input-container select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #e3fe55;
    border-radius: 4px;
  }
  
  .to-text {
    margin: 0 16px;
    font-weight: bold;
  }
  
  .copy-button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .result-text {
    font-size: 18px;
    margin-top: 16px;
  }
  
  .conversion-formula {
    margin-top: 16px;
    font-size: 14px;
    color: #666;
  }
  
  .other-conversions {
    margin-top: 40px;
    text-align: center;
  }
  
  .other-conversions-title {
    font-size: 45px;
    margin-bottom: 24px;
  }
  
  .conversions-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    max-width: 960px;
    margin: 0 auto;
  }
  
  .conversion-link {
    display: block;
    padding: 16px;
    font-size: 16px;
    background-color: #fff;
    border-radius: 4px;
    text-decoration: none;
    color: #333;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .conversion-link img {
    display: block;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    margin-bottom: 8px;
  }
  
  .conversion-link span {
    display: block;
    text-align: center;
  }
  
  .faq-section {
    margin-top: 48px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .faq-title {
    font-size: 36px;
    margin-bottom: 24px;
  }
  
  .faq-item {
    margin-bottom: 24px;
  }
  
  .faq-item h3 {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .faq-item p {
    font-size: 16px;
  }
  
  /* Added a space before the class name */
   .conversion-page {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20%;
    font-family: Arial, sans-serif;
    background-color: #f0f1e9;
  }
  
  /* ... previous CSS code ... */
  
  .conversion-card {
    background-color: #e3fe55;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;
  }
  
  /* ... previous CSS code ... */
  
  .popular-items {
    margin-top: 60px;
    text-align: center;
  }
  
  .popular-items-title {
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .popular-items-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    max-width: 660px;
    margin: 0 auto;
  }
  
  .popular-item {
    background-color: #e3fe55;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .popular-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .popular-item p {
    font-size: 16px;
    color: #666;
  }
  
  .trending-conversions {
    margin-top: 60px;
    text-align: center;
  }
  
  .trending-conversions-title {
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .trending-conversions-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Set 4 equal-width columns */
    gap: 24px; /* Add some spacing between the items */
    max-width: 960px;
    margin: 0 auto;
  }
  
  .trending-conversion {
    background-color: #333; /* Change the background color to black */
    color: #fff; /* Change the text color to white */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none; /* Remove the underline */
  }
  
  .faq-section {
    margin-top: 60px;
  }
  
  .faq-title {
    font-size: 36px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .faq-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 960px;
    margin: 0 auto;
  }
  
  .faq-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    cursor: pointer;
  }
  
  .faq-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .faq-item p {
    font-size: 16px;
    color: #666;
    display: none;
  }
  
  .faq-item.active p {
    display: block;
  }
  
  /* Added a space before the class name */
   .conversion-page {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20%;
    font-family: Arial, sans-serif;
    background-color: #f0f1e9;
  }
  
  /* ... previous CSS code ... */
  
  /* ... previous CSS code ... */
  
  .popular-items {
    margin-top: 60px;
    text-align: center;
  }
  
  .popular-items-title {
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .popular-items-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    max-width: 960px;
    margin: 0 auto;
  }
  
  .popular-item {
    background-color: #e3fe55;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .popular-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .popular-item p {
    font-size: 16px;
    color: #666;
  }
  
  .trending-conversions {
    margin-top: 60px;
    text-align: center;
  }
  
  .trending-conversions-title {
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .trending-conversions-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Set 4 equal-width columns */
    gap: 24px; /* Add some spacing between the items */
    max-width: 960px;
    margin: 0 auto;
  }
  
  .trending-conversion {
    background-color: #333; /* Change the background color to black */
    color: #fff; /* Change the text color to white */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none; /* Remove the underline */
  }
  
  .faq-section {
    margin-top: 60px;
  }
  
  .faq-title {
    font-size: 36px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .faq-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 960px;
    margin: 0 auto;
  }
  
  .faq-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    cursor: pointer;
  }
  
  .faq-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .faq-item p {
    font-size: 16px;
    color: #666;
    display: none;
  }
  
  .faq-item.active p {
    display: block;
  }
  @media (max-width: 300px) {
    .conversion-card {
      padding: 16px;
    }
  
    .conversion-card .input-container input,
    .conversion-card .input-container select {
      font-size: 14px;
    }
  
    .conversion-card .result-text {
      font-size: 16px;
    }
  
    .conversion-card .conversion-formula {
      font-size: 12px;
    }
  }@media (max-width: 600px) {
  .trending-conversions-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 600px) {
    .trending-conversions-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .input-container input,
  .input-container select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .input-container .to-text {
    margin-bottom: 8px;
  }
  
  @media (max-width: 600px) {
    .conversion-card {
      padding: 16px;
    }
  
    .conversion-card .result-text {
      font-size: 16px;
    }
  
    .conversion-card .conversion-formula {
      font-size: 12px;
    }
  
    .trending-conversions-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 600px) {
    .popular-items-grid {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
  .breadcrumbs {
    margin-bottom: 20px;
  }
  
  .breadcrumbs a {
    color: #007bff;
    text-decoration: none;
  }
  
  .breadcrumbs a:hover {
    text-decoration: underline;
  }